/** ***********************************************************************************
 * Lemuria layout design tokens
 *********************************************************************************** */

:root {
  /**
   * Breakpoints
   */

  --lm-global--breakpoints--sm: 48rem;  /** Small: 768px */
  --lm-global--breakpoints--md: 64rem;  /** Medium: 1024px */
  --lm-global--breakpoints--lg: 90rem;  /** Medium: 1440px */


  /**
   * Content grid
   */

  /** Column count */
  --lm-global--content-grid--xs--columncount: 2;
  --lm-global--content-grid--sm--columncount: 12;
  --lm-global--content-grid--columncount: var(--lm-global--content-grid--xs--columncount);

  /** Column size */
  --lm-global--content-grid--xs--columnsize: 9.5vw;     /* 152px */
  --lm-global--content-grid--sm--columnsize: 4.438rem;  /* 71px */
  --lm-global--content-grid--md--columnsize: 5.25rem;   /* 84px */
  --lm-global--content-grid--lg--columnsize: 5.938rem;  /* 95px */
  --lm-global--content-grid--xl--columnsize: 7.938rem;  /* 127px */
  --lm-global--content-grid--columnsize: var(--lm-global--content-grid--xs--columnsize);

  /* 30px gutter */
  --fluid-1615-30: clamp(1.0094rem, -1.1214rem + 3.3293vi, 1.875rem);

  /** Gutter width */
  --lm-global--content-grid--xs--guttersize: .625rem;  /* 10px */
  --lm-global--content-grid--sm--guttersize: var(--fluid-1615-30);
  --lm-global--content-grid--guttersize: var(--lm-global--content-grid--xs--guttersize);

  /* 39px laterali */
  --fluid-21-39: clamp(1.3125rem, -1.4567rem + 4.3269vi, 2.4375rem);

  --lm-global--page--paddingsize: 1.25rem;  /* 20px */
  --lm-global--page--sm--paddingsize: var(--fluid-21-39);

  --lm-global--content-grid--GridTemplateColumns: repeat(var(--lm-global--content-grid--columncount), minmax(var(--lm-global--content-grid--columnsize), 1fr));
  --lm-global--page-grid--sm--GridTemplateColumns: repeat(var(--lm-global--content-grid--columncount), 1fr); /* minmax(71px, 127px) */

}

/* 768px */
/* @media all and (min-width: 48rem) {
  :root {
    --lm-global--content-grid--columncount: var(--lm-global--content-grid--sm--columncount);
    --lm-global--content-grid--columnsize: var(--lm-global--content-grid--sm--columnsize);
    --lm-global--content-grid--guttersize: var(--lm-global--content-grid--sm--guttersize);
    --lm-global--page--paddingsize: var(--lm-global--page--sm--paddingsize);
  }
} */

/* 1024px */
@media all and (min-width: 64rem) {
  :root {
    --lm-global--content-grid--columncount: var(--lm-global--content-grid--sm--columncount);
    --lm-global--content-grid--columnsize: var(--lm-global--content-grid--sm--columnsize);
    --lm-global--content-grid--guttersize: var(--lm-global--content-grid--sm--guttersize);
    --lm-global--page--paddingsize: var(--lm-global--page--sm--paddingsize);
  }
}
