/** ***********************************************************************************
 * Lemuria common styles
 *********************************************************************************** */

 :root {
  --lm-c-page--Font: var(--lm-global--Font-paragraph--md-regular);

  /**
   * Default color palette
   */

  --lm-c-page--AccentColor: var(--lm-global--palette-light-blue);
  --lm-c-page--BackgroundColor: var(--lm-global--palette-white);
  --lm-c-page--Color: var(--lm-global--palette-dark-grey);
}



/**
 * Page
 * 1: reset
 */

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;  /* 1 */
  padding: 0;  /* 1 */
}

html {
  scroll-behavior: smooth;
}

body {
  --lm-c-page--Grid-Template: var(--lm-global--content-grid--GridTemplateColumns);
  --lm-c-page--Grid-Gap: clamp(10px, 2vw, 15px);

  --lm-c-page--sm--Grid-Template: var(--lm-global--page-grid--sm--GridTemplateColumns);
}

/**
 * Breakpoint: small
 */

@media all and (min-width: 64rem) {
  body {
    --lm-c-page--Grid-Template: var(--lm-c-page--sm--Grid-Template);
    --lm-c-page--Grid-Gap: clamp(15px, 1.5vw, 26px);
  }
}

body {
  accent-color: var(--lm-c-page--AccentColor);
  background-color: var(--lm-c-page--BackgroundColor);
  color: var(--lm-c-page--Color);
  font: var(--lm-c-page--Font);
}



/**
 * Label
 */

label:has(input) {
  cursor: pointer;
}

label[for] {
  cursor: pointer;
}



.island {
  background-color: rgb(236, 227, 159);
  border-top-left-radius: 277% 277%;
  bottom: -126px;
  filter: blur(44px);
  height: 384px;
  opacity: 0.462642;
  position: fixed;
  right: -81px;
  width: 321px;
  z-index: 1;
}



.sticky-highlight {
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, .6) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 5rem;
  left: 0;
  max-width: 100% !important;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  touch-events: none;
  transform: translateY(-200%) !important;
  transition: all var(--lm-global--Duration--md) ease;
  visibility: hidden;
  z-index: 9;
}

  .sticky-highlight.scroll-up {
    transform: translateY(0) !important;
    visibility: visible;
  }
