/** ***********************************************************************************
 * Lemuria typography design tokens
 *********************************************************************************** */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@font-face {
  font-family: "Larsseit";
  src: url("~/assets/fonts/larsseit-webfont.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Larsseit";
  src: url("~/assets/fonts/larsseit-thin-webfont.woff2") format("woff2");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Larsseit";
  src: url("~/assets/fonts/larsseit-light-webfont.woff2") format("woff2");
  font-style: normal;
  font-weight: 200 300;
}
@font-face {
  font-family: "Larsseit";
  src: url("~/assets/fonts/larsseit-medium-webfont.woff2") format("woff2");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Larsseit";
  src: url("~/assets/fonts/larsseit-bold-webfont.woff2") format("woff2");
  font-style: normal;
  font-weight: 600 700;
}
@font-face {
  font-family: "Larsseit";
  src: url("~/assets/fonts/larsseit-extrabold-webfont.woff2") format("woff2");
  font-style: normal;
  font-weight: 800 900;
}
@font-face {
  font-family: "Larsseit";
  src: url("~/assets/fonts/larsseit-italic-webfont.woff2") format("woff2");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "EuclidCircularB";
  src: url("~/assets/fonts/EuclidCircularB-Medium-WebS.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

:root {
  /**
   * Font families
   */

  --lm-global--FontFamily-default: "Larsseit", "Roboto", sans-serif;


  /**
   * Font weights
   */

  --lm-global--FontWeight-thin:       100;
  --lm-global--FontWeight-light:      300;
  --lm-global--FontWeight-normal:     400;
  --lm-global--FontWeight-medium:     500;
  --lm-global--FontWeight-bold:       700;
  --lm-global--FontWeight-extrabold:  900;
  --lm-global--FontWeight-regular: var(--lm-global--FontWeight-normal);


  /**
   * Font sizes
   */

  /* @link https://utopia.fyi/clamp/calculator?a=1024,1440,42.67—60 */
  --fluid-4267-60: clamp(2.6669rem, 0.0007rem + 4.1659vi, 3.75rem);
  --fluid-8533-120: clamp(5.3331rem, -0.0007rem + 8.3341vi, 7.5rem);

  --fluid-1351-19: clamp(0.8444rem, -0.0002rem + 1.3197vi, 1.1875rem);
  --fluid-1564-22: clamp(0.9775rem, -0.001rem + 1.5288vi, 1.375rem);
  --fluid-2133-30: clamp(1.3331rem, -0.0007rem + 2.0841vi, 1.875rem);
  --fluid-2489-35: clamp(1.5556rem, 0.0002rem + 2.4303vi, 2.1875rem);

  --fluid-996-14: clamp(0.6225rem, 0.001rem + 0.9712vi, 0.875rem);
  --fluid-1138-16: clamp(0.7113rem, 0.0005rem + 1.1106vi, 1rem);
  --fluid-128-18: clamp(0.8rem, 0rem + 1.25vi, 1.125rem);
  --fluid-1422-20: clamp(0.8888rem, -0.0005rem + 1.3894vi, 1.25rem);
  --fluid-1778-25: clamp(1.1113rem, 0.0005rem + 1.7356vi, 1.5625rem);
  --fluid-2844-40: clamp(1.7775rem, -0.001rem + 2.7788vi, 2.5rem);

  --lm-global--FontSize-title--xl:     var(--fluid-4267-60);   /* Extra large: 60px */
  --lm-global--FontSize-title--hg:     var(--fluid-8533-120);  /* Humongous: 120px */

  --lm-global--FontSize-paragraph--sm: var(--fluid-1351-19);   /* Small: 19px */
  --lm-global--FontSize-paragraph--md: var(--fluid-1564-22);   /* Medium: 22px */
  --lm-global--FontSize-paragraph--lg: var(--fluid-2133-30);   /* Large: 30px */
  --lm-global--FontSize-paragraph--xl: var(--fluid-2489-35);   /* Extra large: 35px */

  --lm-global--FontSize-label--xs:     var(--fluid-996-14);    /* Extra small: 14px */
  --lm-global--FontSize-label--sm:     var(--fluid-1138-16);   /* Small: 16px */
  --lm-global--FontSize-label--md:     var(--fluid-128-18);    /* Medium: 18px */
  --lm-global--FontSize-label--lg:     var(--fluid-1422-20);   /* Large: 20px */
  --lm-global--FontSize-label--xl:     var(--fluid-1778-25);   /* Extra large: 25px */
  --lm-global--FontSize-label--2xl:    var(--fluid-2844-40);   /* Extra extra large: 40px */


  /**
   * Text styles
   */

  --lm-global--Font-title--xl-bold: var(--lm-global--FontWeight-bold) var(--lm-global--FontSize-title--xl) var(--lm-global--FontFamily-default);
  --lm-global--Font-title--hg-thin: var(--lm-global--FontWeight-thin) var(--lm-global--FontSize-title--hg) var(--lm-global--FontFamily-default);
  --lm-global--Font-title--hg-light: var(--lm-global--FontWeight-light) var(--lm-global--FontSize-title--hg) var(--lm-global--FontFamily-default);

  --lm-global--Font-paragraph--sm-regular: var(--lm-global--FontWeight-regular) var(--lm-global--FontSize-paragraph--sm) var(--lm-global--FontFamily-default);
  --lm-global--Font-paragraph--md-light: var(--lm-global--FontWeight-light) var(--lm-global--FontSize-paragraph--md) var(--lm-global--FontFamily-default);
  --lm-global--Font-paragraph--md-regular: var(--lm-global--FontWeight-regular) var(--lm-global--FontSize-paragraph--md) var(--lm-global--FontFamily-default);
  --lm-global--Font-paragraph--md-bold: var(--lm-global--FontWeight-bold) var(--lm-global--FontSize-paragraph--md) var(--lm-global--FontFamily-default);
  --lm-global--Font-paragraph--lg-bold: var(--lm-global--FontWeight-bold) var(--lm-global--FontSize-paragraph--lg) var(--lm-global--FontFamily-default);
  --lm-global--Font-paragraph--xl-medium: var(--lm-global--FontWeight-medium) var(--lm-global--FontSize-paragraph--xl) var(--lm-global--FontFamily-default);

  --lm-global--Font-label--xs-light: var(--lm-global--FontWeight-light) var(--lm-global--FontSize-label--xs) var(--lm-global--FontFamily-default);
  --lm-global--Font-label--sm-regular: var(--lm-global--FontWeight-regular) var(--lm-global--FontSize-label--sm) var(--lm-global--FontFamily-default);
  --lm-global--Font-label--sm-extrabold: var(--lm-global--FontWeight-extrabold) var(--lm-global--FontSize-label--sm) var(--lm-global--FontFamily-default);
  --lm-global--Font-label--md-medium: var(--lm-global--FontWeight-medium) var(--lm-global--FontSize-label--md) var(--lm-global--FontFamily-default);
  --lm-global--Font-label--lg-light: var(--lm-global--FontWeight-light) var(--lm-global--FontSize-label--lg) var(--lm-global--FontFamily-default);
  --lm-global--Font-label--lg-regular: var(--lm-global--FontWeight-regular) var(--lm-global--FontSize-label--lg) var(--lm-global--FontFamily-default);
  --lm-global--Font-label--lg-bold: var(--lm-global--FontWeight-bold) var(--lm-global--FontSize-label--lg) var(--lm-global--FontFamily-default);
  --lm-global--Font-label--xl-light: var(--lm-global--FontWeight-light) var(--lm-global--FontSize-label--xl) var(--lm-global--FontFamily-default);
  --lm-global--Font-label--2xl-bold: var(--lm-global--FontWeight-bold) var(--lm-global--FontSize-label--2xl) var(--lm-global--FontFamily-default);
}
