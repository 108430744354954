/** ***********************************************************************************
 * Color design tokens
 *********************************************************************************** */

:root {
  /**
   * Primary
   */

  /** White: #fff */
  --lm-global--palette-white--100: rgba(255, 255, 255, 1);
  --lm-global--palette-white--70: rgba(255, 255, 255, .7);
  --lm-global--palette-white--65: rgba(255, 255, 255, .65);
  --lm-global--palette-white--30: rgba(255, 255, 255, .3);
  --lm-global--palette-white--25: rgba(255, 255, 255, .25);
  --lm-global--palette-white: var(--lm-global--palette-white--100);

  /** Dark grey: #221f20 */
  --lm-global--palette-dark-grey--100: rgba(34, 31, 32, 1);
  --lm-global--palette-dark-grey--56: rgba(34, 31, 32, .56);
  --lm-global--palette-dark-grey--56: rgba(34, 31, 32, .56);
  --lm-global--palette-dark-grey--49: rgba(34, 31, 32, .49);
  --lm-global--palette-dark-grey--30: rgba(34, 31, 32, .30);
  --lm-global--palette-dark-grey--27: rgba(34, 31, 32, .27);
  --lm-global--palette-dark-grey: var(--lm-global--palette-dark-grey--100);


  /**
   * Accent
   */

  /** Aggressive aqua: #00f9ff */
  --lm-global--palette-aqua--100: rgba(0, 249, 255, 1);
  --lm-global--palette-aqua: var(--lm-global--palette-aqua--100);

  /** Light blue: #00538f */
  --lm-global--palette-light-blue--100: rgba(0, 83, 143, 1);
  --lm-global--palette-light-blue--80: rgba(0, 83, 143, .8);
  --lm-global--palette-light-blue--45: rgba(0, 83, 143, .45);
  --lm-global--palette-light-blue--30: rgba(0, 83, 143, .3);
  --lm-global--palette-light-blue: var(--lm-global--palette-light-blue--100);

  /** Dusty blue: #afd3e5 */
  --lm-global--palette-dusty-blue--100: rgba(175, 211, 229, 1);
  --lm-global--palette-dusty-blue--51: rgba(175, 211, 229, .51);
  --lm-global--palette-dusty-blue--46: rgba(175, 211, 229, .46);
  --lm-global--palette-dusty-blue: var(--lm-global--palette-dusty-blue--100);

  --lm-global--palette-dusty-blue--100-r: 175;
  --lm-global--palette-dusty-blue--100-g: 211;
  --lm-global--palette-dusty-blue--100-b: 229;
  --lm-global--palette-dusty-blue--40-a: .4;
  --lm-global--palette-dusty-blue--40-r: calc(var(--lm-global--palette-dusty-blue--100-r) + (255 - var(--lm-global--palette-dusty-blue--100-r)) * (1 - var(--lm-global--palette-dusty-blue--40-a)));
  --lm-global--palette-dusty-blue--40-g: calc(var(--lm-global--palette-dusty-blue--100-g) + (255 - var(--lm-global--palette-dusty-blue--100-g)) * (1 - var(--lm-global--palette-dusty-blue--40-a)));
  --lm-global--palette-dusty-blue--40-b: calc(var(--lm-global--palette-dusty-blue--100-b) + (255 - var(--lm-global--palette-dusty-blue--100-b)) * (1 - var(--lm-global--palette-dusty-blue--40-a)));
  --lm-global--palette-dusty-blue--40:  rgba(var(--lm-global--palette-dusty-blue--40-r), var(--lm-global--palette-dusty-blue--40-g), var(--lm-global--palette-dusty-blue--40-b), 1);


  /**
   * Secondary
   */

  /** Dark blue: #002E52 */
  --lm-global--palette-dark-blue--100: rgba(0, 46, 82, 1);
  --lm-global--palette-dark-blue--65: rgba(0, 46, 82, .65);
  --lm-global--palette-dark-blue--31: rgba(0, 46, 82, .31);
  --lm-global--palette-dark-blue: var(--lm-global--palette-dark-blue--100);

  /** Sky blue: #b3eeff */
  --lm-global--palette-sky-blue--100: rgba(179, 238, 255, 1);
  --lm-global--palette-sky-blue--50: rgba(179, 238, 255, .5);
  --lm-global--palette-sky-blue--18: rgba(179, 238, 255, .18);
  --lm-global--palette-sky-blue: var(--lm-global--palette-sky-blue--100);

  /** Prussian blue (blu_mapps): #003762 */
  --lm-global--palette-prussian-blue--100: rgba(0, 55, 98, 1);
  --lm-global--palette-prussian-blue: var(--lm-global--palette-prussian-blue--100);

  /**
   * Gradient colors
   */

  --lm-global--palette-gradient--background: linear-gradient(180deg, rgba(175, 211, 229, 0) 0%, rgba(175, 211, 229, 0.65) 65%, rgba(175, 211, 229, 1) 100%);

}
