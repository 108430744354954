/** ***************************************************************************
 * Lemuria utility classes
 *************************************************************************** */

/**
 * Hidden
 */

.lm-u-hidden {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.lm-u-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


/**
 * Button
 */

button.lm-u-button {
  appearance: none;
  background-color: inherit;
  border: none;
  color: currentcolor;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  outline: none;
  padding: 0;
}

a.lm-u-button {
  text-decoration: none;
}


/**
 * Force visibility
 */

.make-visible {
  visibility: visible !important;

  > * {
    opacity: 1 !important;
  }
}


/**
 * Layout
 */

.lm-u-center-absolute {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* apply to children of element with template columns */
.lm-u-subgrid_alternative {
  /* span the whole grid */
  grid-column: 1 / -1;

  /* create another grid and inherit the values from the parent grid */
  display: inherit;
  grid-template-columns: inherit;
  grid-gap: inherit;

  /* overwrite the display for browsers that understand display: contents */
  /* display: contents; */
}
