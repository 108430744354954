/** ***********************************************************************************
 * Common color design tokens
 *********************************************************************************** */

:root {

  /**
   * Accent
   */

  /** Demonic kiss: #cf2d4a */
  --lm-global-common--palette-kiss--100: rgba(207, 45, 74, 1);
  --lm-global-common--palette-kiss: var(--lm-global-common--palette-kiss--100);

}
