/** ***********************************************************************************
 * Lemuria miscellaneous design tokens
 *********************************************************************************** */

:root {
  /**
   * Durations
   */

  --lm-global--Duration--tn: 100ms;
  --lm-global--Duration--xs: 150ms;
  --lm-global--Duration--sm: 300ms;
  --lm-global--Duration--md: 700ms;
  --lm-global--Duration--lg: 1400ms;
  --lm-global--Duration--xl: 1800ms;


  /**
   * Timing functions
   */

  --lm-global--AnimationTimingFunction--bounce: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  --lm-global--AnimationTimingFunction--easeoutcubic: cubic-bezier(0.33, 1, 0.68, 1);
}
